import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostDetail, PostItems } from "../components/post"

const PostTemplate = ({ data: { post } }) => {
  return (
    <Layout post={post} page={post.frontmatter.slug}>
      <SEO
        title={`${post.frontmatter.title}${
          post.frontmatter.title2 ? ` & ${post.frontmatter.title2}` : ""
        }`}
        description={post.frontmatter.description}
        image={post.frontmatter.socialImage.publicURL}
        ogType="article"
      />
      <PostDetail post={post} />
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query($slug: String) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      ...PostFields
    }
  }
`
